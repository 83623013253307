<template>
  <v-container
    id="schdules-sms"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <base-v-component
      heading="Scheduled SMS"
      sub-heading="Scheduled SMS List"
    />

    <template>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="searchText"
          class="searchBox"
          label="Search"
          @keyup="_filterSmsList"
        />
        <v-spacer />
        <import-scheduled-sms
          :groups="groups"
          @on-upload-start="importStart"
          @on-success="importSuccess"
          @on-error="importError"
        />
      </v-toolbar>
    </template>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredSchduledSmsList"
      item-key="cellNumber"
      sort-by="name"
    >
      <template v-slot:no-data>
        <p class="mb-4 mt-4">
          No results
        </p>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="initialize"
        >
          Refresh
        </v-btn>
      </template>
      <template v-slot:item.Media="{ item }">
        <table-cell-image-viewer :image-u-r-l="item.MediaURL" />
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import { VueMaskDirective } from 'v-mask'
  import Vue from 'vue'
  import moment from 'moment'
  import ImportScheduledSms from '../components/ImportScheduledSMS.vue'
  import TableCellImageViewer from '../components/TableCellImageViewer.vue'
  Vue.directive('mask', VueMaskDirective)

  export default {
    components: {
      ImportScheduledSms,
      TableCellImageViewer,
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      dialog: false,
      groups: [],
      headers: [
        { text: 'Campaign Name', value: 'CampaignName' },
        { text: 'Message', value: 'Message' },
        { text: 'Send Date', value: 'FormattedSendDate' },
        { text: 'Media', value: 'Media' },
      ],
      snackbar: false,
      notification: 'Unknown',
      searchText: '',
      isOverlayVisible: false,
      schduledSmsList: [],
      filteredSchduledSmsList: [],
    }),

    async created () {
      this.username = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {

      async initialize () {
        try {
          this.isOverlayVisible = true
          this._loadGroups()
          const schduledSmsResponse = await this.httpConnector.makeRequest('post', `${this.apiEndpoint}sms/schedule/list?code=${this.apiCode}`, {
            StartDate: new Date(),
            EndDate: moment().add(1, 'year').toDate(), // can be 1 year from now
          })
          if (schduledSmsResponse && schduledSmsResponse.data) {
            // Process here
            this.schduledSmsList = schduledSmsResponse.data.map(message => ({
              ...message,
              FormattedSendDate: moment(message.StartDate).format('MM/DD/YYYY h:mm a'),
            }))
            this._filterSmsList()
          }
          this.isOverlayVisible = false
        } catch (error) {
          this.schduledSmsList = this._generateDemoList()
          this._filterSmsList()
          this.isOverlayVisible = false
        }
      },

      async _loadGroups () {
        const groupResponse = await this.httpConnector.makeEncryptedRequest(
          'get',
          `${this.apiEndpoint}groups?code=${this.apiCode}`,
        )
        this.groups = []
        groupResponse.forEach(group => {
          this.groups.push({
            id: group.groupID,
            name: group.name,
          })
        })
      },

      _generateDemoList () {
        return [{
          name: 'Jon Doe',
          schduledOn: '12-12-2021 11:59 AM',
        }]
      },

      importStart () {
        this.isOverlayVisible = true
      },

      async importSuccess (res) {
        this.isOverlayVisible = false
        await this.initialize()
        this.notification = 'Imported successfully'
        this.snackbar = true
      },

      importError (error) {
        this.isOverlayVisible = false
        this.notification = 'Unable to import'
        this.snackbar = true

        throw error
      },

      _filterSmsList () {
        this.filteredSchduledSmsList = [...this.schduledSmsList]
        const searchText = this.searchText.trim().toLowerCase()
        if (searchText && searchText.length) {
          this.filteredSchduledSmsList = this.filteredSchduledSmsList.filter(sms => {
            if (sms.name && sms.name.length && sms.name.toLowerCase().indexOf(searchText) > -1) {
              return true
            }
          })
        }
      },

      _deleteScheduledSMS (item) {
      },
    },
  }

</script>
<style scoped>
  .v-overlay.v-overlay--active.theme--dark {
    z-index: 300!important;
  }
  .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
</style>
