import { UserManager, WebStorageStateStore } from 'oidc-client'

export default class AuthService {
  constructor () {
    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: process.env.VUE_APP_AZURE_AUTHORITY,
      client_id: process.env.VUE_APP_AZURE_CLIENT_ID,
      redirect_uri: `${process.env.VUE_APP_AZURE_REDIRECT_URI}/callback.html`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${process.env.VUE_APP_AZURE_REDIRECT_URI}/silent-renew.html`,
      response_type: 'code',
      scope: 'openid profile email',
      post_logout_redirect_uri: `${process.env.VUE_APP_AZURE_REDIRECT_URI}`,
      filterProtocolClaims: true,
    }
    this.userManager = new UserManager(settings)
  }

  async getUser () {
    return await this.userManager.getUser()
  }

  async login () {
    return await this.userManager.signinRedirect()
  }

  async logout () {
    return await this.userManager.signoutRedirect()
  }

  async getAccessToken () {
    return await this.userManager.getUser().then(data => {
      return data.access_token
    })
  }

  async getUserEmail () {
    return await this.userManager.getUser().then(data => {
      return data.profile.preferred_username
    })
  }

  async isLoggedIn () {
    const user = await this.getUser()
    return user !== null && !user.expired
  }
}
