<template>
  <div
    v-if="imageURL"
    class="iconWrapper"
  >
    <div class="icon">
      <img
        src="https://icon-library.com/images/image-icon/image-icon-13.jpg"
        alt="icon"
      > &nbsp; View Attachment
    </div>
    <div class="imageViewer">
      <img
        :src="imageURL"
        alt="mediaURL"
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TableCellImageViewer',
    props: {
      imageURL: {
        type: String,
        default: null,
      },
    },

  }
</script>

<style lang="scss" scoped>
.iconWrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    // background-color: red;
    cursor: pointer;
    // width: 200px;
    align-items: center;
    .icon {
        img {
            width: 20px;
        }
    }
    * {
        transition: 0.3s;
    }
    .imageViewer {
        position: fixed;
        bottom: 0px;
        right: -700px;
        // display: none;
        height: 300px;
        display: unset;
        padding: 10px;
        height: 300px;
        border-radius: 5px;
        background-color: rgb(221, 166, 0);
        img {
                width: 500px;
                height: 100%;
                object-fit: cover;
                z-index: 999999;
            }
    }
    &:hover {
        .imageViewer {
            right: 0px;

        }
    }
}
</style>
