<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        dark
        class="mb-2 mr-8"
        v-bind="attrs"
        v-on="on"
      >
        Import Scheduled SMS
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Import Scheduled SMS</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                v-model="field.file"
                label="File (CSV) *"
                truncate-length="24"
                accept="text/csv"
                required
                :error="!field.file && isFormDirty"
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="field.groupId"
                :items="groups"
                label="Group *"
                item-text="name"
                item-value="id"
                required
                :error="!field.groupId && isFormDirty"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="upload"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import AuthService from '@/services/AuthService'
  const auth = new AuthService()

  export default {
    name: 'ImportScheduledSMS',
    props: {
      groups: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      user: null,
      dialog: false,
      field: {
        file: null,
        groupId: '',
      },
      isFormDirty: false,
      snackbar: false,
      notification: 'Unknown',
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      this.user = await auth.getUser()
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      async initialize () {
        this.groups = []

        this.httpConnector
          .makeRequest('get', `${this.apiEndpoint}groups?code=${this.apiCode}`)
          .then(res => {
            res.data.forEach(group => {
              this.groups.push({
                id: group.groupID,
                name: group.name,
              })
            })

            this.close()
          })
          .catch(error => {
            throw error
          })
      },

      close () {
        this.dialog = false
        this.field = {
          file: null,
          groupId: '',
        }
        this.isFormDirty = false
        this.snackbar = false
      },

      isFormValid () {
        let isValid = true
        if (!this.field.groupId) {
          isValid = false
        }
        if (!this.field.file) {
          isValid = false
        }
        return isValid
      },

      upload () {
        this.isFormDirty = true
        if (!this.isFormValid()) {
          this.notification = 'Fill all the required fields'
          this.snackbar = true
          return
        }
        this.$emit('on-upload-start')
        const formData = new FormData()

        formData.append('file', this.field.file)
        formData.append('createdBy', this.usremail)
        formData.append('groupID', this.field.groupId)

        this.httpConnector
          .makeRequest(
            'post',
            `${this.apiEndpoint}schedulesms/import?code=${this.apiCode}`,
            formData,
            {
              'Content-Type': 'multipart/form-data',
            },
          )
          .then(res => {
            this.$emit('on-success', res)
            this.close()
          })
          .catch(error => {
            this.$emit('on-error', error)
          })
      },
    },
  }
</script>
